.view-icons {
    float: right;
    margin-right: 10px;
    @include respond-below(custom767) {
        margin-right: 0;
    }
    .btn{
        background-color: $white;
        border: 1px solid $light-900;
        color: $secondary-800;
        font-size: $font-size-18;
        min-width: 40px;
        @include margin-padding(0 5px 0 0, 7px 0 3px 0);
        &.active {
            color: $secondary-900;
        }
        @include respond-below(custom767) {
            min-width: 37px;
            @include margin-padding(0 5px 0 0, 5px 0 3px 0);
        }
    }
}
.page-header {
    .view-icons {
        @include respond-below(custom767) {
            margin-right: 10px;
        }
        @include respond-below(custom575) {
            margin-right: 0;
        }
    }
}
.add-btn {
    // background-color: $primary;
     background-color: #06ad97;
    // border: 1px solid $primary;
    color: $white;
    float: right;
    font-weight: $font-weight-medium;
    min-width: 140px;
    @include rounded(50px);
    i {
        margin-right: 5px;
    }
    &:hover{
        color: $white;
        background-color: $primary;
        border: 1px solid $primary;
    }
}
.avatar-dropdown{
    .dropdown-toggle{
        &:after{
            display: none;
        }
    }
}
.profile-widget {
    background-color: $white;
    border: 1px solid $secondary-100;
    margin-bottom: 30px;
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    @include margin-padding(null, null);
    @include rounded(4px);
    @include box-shadow(null, 0, 1px, 1px, 0, rgba(0, 0, 0, .20));
    .profile-img {
        cursor: pointer;
        height: 80px;
        margin: 0 auto;
        position: relative;
        width: 80px;
        .avatar {
            font-size: $font-size-24;
            height: 80px;
            line-height: 80px;
            margin: 0;
            width: 80px;
        }
    }
    .dropdown.profile-action {
        text-align: right;
        @include position(absolute,10px,5px,null,null);
        .action-icon {
            color: $secondary-600;
            font-size: $font-size-18;
            display: inline-block;
        }
        .dropdown-toggle{
            &::after{
                display: none;
            }
        }
    }
    .user-name {
        color: $secondary-900;
        a {
            color: $secondary-900;
        }
    }
}
.stats-info {
    background-color: $white;
    border: 1px solid $secondary-100;
    text-align: center;
    @include rounded(4px);
    @include margin-padding(0 0 20px, 15px);
    h6 {
        color: $secondary;
        font-size: $font-size-16;
        font-weight: normal;
        line-height: 18px;
        margin-bottom: 5px;
    }
    h4 {
        font-size: $font-size-24;
        margin-bottom: 0;
        span {
            color: $secondary-700;
            font-size: $font-size-12;
        }
    }
}
.leave-item {
    max-width: 653px;
    .leave-row {
        margin-bottom: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .leave-left {
            flex: 1 1 auto;
            .input-box {
                max-width: 410px;
            }
        }
        .input-block {
            margin-bottom: 0;
        }
    }
    .leave-right {
        flex: 0 0 200px;
        margin-top: 31px;
        min-height: 44px;
        text-align: right;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: flex-end;
        -webkit-justify-content: end;
        -ms-flex-pack: end;
        .btn {
            + .btn {
                margin-left: 10px;
            }
        }
        .leave-edit-btn {
            color: $info;
            background-color: transparent;
            border: 0;
            padding: 0 6px;
            transition: unset;
        }
    }
}
.leave-header {
    color: $secondary;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    .title {
        flex: 1 1 auto;
    }
    .leave-action {
        flex: 1 1 auto;
        text-align: right;
    }
}
.half-day {
    width: 15px;
    .first-off {
        white-space: pre-wrap;
    }
}